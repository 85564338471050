<template>
  <!-- welcome -->
    <div :key="randomKey" id="blog-not-found" :class="`container-fluid`">
      <div class="container flex-row content-wrap content-center align-start ">
        <div class="col-12 ">
          <h1>{{ $tl('blog.notFound') }}</h1>
          <span class="divider"></span>
          <p>{{ $tl('blog.notFoundText') }}</p>
          <p>{{ $tl('blog.lookHere') }} <a :href="getLanguageLink($tl('links.blogUrl'))">{{ $tl('links.blog')}}</a></p> 
        </div>
        
      </div>
    </div>
</template>

<script>
import GenerateImagePaths from '../mixins/GenerateImagePaths.vue'
import LanguageSwitching from '../mixins/LanguageSwitching.vue'

export default {
  name: 'BlogEntryNotFound',
  emits: [],
  props: {
    count: Number
  },
  data() {
    return {
    }
  },
  computed: {
    randomKey: function() {
      return this.randPrefix+'-'+this.randPart
    }
  },
  components: {
  },
  mixins: [
    GenerateImagePaths,
    LanguageSwitching
  ],
  methods: {
    getDateString( dateStr ) {
      var myDateStr = new Date(dateStr)
      //console.log(dateStr, myDateStr)
      return myDateStr.toLocaleString('de-DE', {day: 'numeric', month: 'long', year: 'numeric'} )
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>