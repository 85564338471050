<template >
  <MenuBar ref="menubar" :menuItems="menuLinks" :languages="languages" :initSelectedLanguage="selectedLanguage" @update-selected-language="updateSelectedLanguage"/>

    <div :key="randomKey" id="not-found" :class="`container-fluid`">
      <div class="container flex-row content-wrap content-center align-start ">
        <div class="col-12 ">
          <h1>{{ $tl('notFound.title') }}</h1>
          <span class="divider"></span>
          <p>{{ $tl('notFound.text') }}</p>
          <p>{{ $tl('notFound.toStartpage') }} <a :href="getLanguageLink($tl('links.startUrl'))">{{ $tl('links.start')}}</a></p> 
        </div>
        
      </div>
    </div>
  <Footer ref="footer" :tags="blog.tags" :social="socialLinks"/>
</template>

<script>
import Footer from '../components/FooterComponent.vue'
import MenuBar from '../components/MenuBarComponent.vue'

import DataDefinitions from '../mixins/DataDefinitions.vue'
import LanguageSwitching from '../mixins/LanguageSwitching.vue'
import AnimateTopElements from '../mixins/AnimateTopElements.vue'
import UpdateDocumentHeader from '../mixins/UpdateDocumentHeader.vue'

export default {
  name: '404',
  components: {
    MenuBar,
    Footer
  },
  mixins: [
    //GenerateImagePaths
    DataDefinitions,
    LanguageSwitching,
    AnimateTopElements,
    UpdateDocumentHeader
  ],
  mounted() {
    this.$nextTick(function () {
      // Code that will run only after the
      // entire view has been rendered
      this.updateDocumentHeader( this.$tl('notFound.title'), this.$tl('notFound.text') )
    })
    window.addEventListener('scroll', () => this.scrollHandling());
  },
  data() {
    return {
      elemsToForceUpdate: [
        'menubar',
        'start',
        'entries',
        'entry',
        'entryNotFound',
        'footer'
      ],
      entryChosen: false,
      entryValid: false,
      chosenEntry: null,
      tagChosen: false,
      tagValid: false,
      chosenTag: null
    }
  },
  computed: {
  },
  inject: ['i18n','currentRoute'],
  methods: {
  }
}
</script>

<style>
</style>
