<template>
  <!-- welcome -->
    <div :key="randomKey" id="home-books" :class="`container-fluid`">
      <div class="container flex-row content-wrap content-center align-start home-container">
        <div class="col-12 home-container-centered">
          <h2 class="text-hover-shine">{{ $tl('books.homeTitle') }}</h2>
          <p>{{ $tl('books.homeIntro') }}</p>
          <span class="divider-light"></span>
        </div>
        <div v-for="book in books"
          :key="book.id"
          class="col-12 col-md-6 book-home hover-shine-big">
          <div class="book-home-title">
            <h3>{{ book.title }}</h3>
            <h4>{{ book.subtitle }}</h4>
          </div>
          <div class="book-home-image">
            <img class="hover-shine-big" :src="getImagePath( book.image, book.imagetype )" />
            <span class="image-right" v-html="book.imageRight"></span>
          </div>
          <div class="book-home-description" v-html="book.description"></div>
          <div class="book-home-morelink">
            <a class="button-link-dark hover-shine hover-scale-little smooth-transition-fast" :href="getLanguageLink($tl('links.booksUrlPart')+book.url)">
              {{ $tl('books.moreLink') }}
              <img class="button-link-icon" :src="getImagePath('arrow-right-light', 'svg')" />
            </a>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import GenerateImagePaths from '../mixins/GenerateImagePaths.vue'
import LanguageSwitching from '../mixins/LanguageSwitching.vue'

export default {
  name: 'StartpageBooks',
  emits: [],
  props: {
    books: Array
  },
  data() {
    return {
    }
  },
  computed: {
    randomKey: function() {
      return this.randPrefix+'-'+this.randPart
    }
  },
  components: {
  },
  mixins: [
    GenerateImagePaths,
    LanguageSwitching
  ],
  methods: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>