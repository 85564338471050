<template >
  <MenuBar ref="menubar" :menuItems="menuLinks" :languages="languages" :initSelectedLanguage="selectedLanguage" @update-selected-language="updateSelectedLanguage"/>

    <div :key="randomKey" id="not-found" :class="`container-fluid`">
      <div class="container flex-row content-wrap content-center align-start ">
        <div class="col-12 ">
          <h1 class="text-hover-shadow">{{ $tl('imprint.title') }}</h1>
          <span class="divider"></span>
          <p>{{ $tl('imprint.responsible') }}</p>
          <p>{{ $tl('imprint.address') }}</p>
          <p><a :href="`mailto:${$tl('imprint.mail')}`">{{ $tl('imprint.mail')}}</a></p>
          <h2 class="mt-50 text-hover-shadow">{{ $tl('imprint.whoWeAre') }}</h2>
          <span class="divider"></span>
          <p>{{ $tl('imprint.whoWeAreText') }} <a :href="$tl('imprint.whoWeAreUrl')">{{ $tl('imprint.whoWeAreUrl') }}</a></p>
          <h2 class="mt-50 text-hover-shadow">{{ $tl('imprint.data') }}</h2>
          <span class="divider"></span>
          <p>{{ $tl('imprint.dataText') }}</p>
          <h3 class="mt-50 text-hover-shadow">{{ $tl('imprint.cookies') }}</h3>
          <span class="divider"></span>
          <p>{{ $tl('imprint.cookiesText1') }}</p>
          <p>{{ $tl('imprint.cookiesText2') }}</p>
          <h3 class="mt-50 text-hover-shadow">{{ $tl('imprint.otherSites') }}</h3>
          <span class="divider"></span>
          <p>{{ $tl('imprint.otherSitesText1') }}</p>
          <p>{{ $tl('imprint.otherSitesText2') }}</p>
        </div>
        
      </div>
    </div>
  <Footer ref="footer" :tags="blog.tags" :social="socialLinks"/>
</template>

<script>
import Footer from '../components/FooterComponent.vue'
import MenuBar from '../components/MenuBarComponent.vue'

import DataDefinitions from '../mixins/DataDefinitions.vue'
import LanguageSwitching from '../mixins/LanguageSwitching.vue'
import AnimateTopElements from '../mixins/AnimateTopElements.vue'
import UpdateDocumentHeader from '../mixins/UpdateDocumentHeader.vue'

export default {
  name: 'Imprint',
  components: {
    MenuBar,
    Footer
  },
  mixins: [
    //GenerateImagePaths
    DataDefinitions,
    LanguageSwitching,
    AnimateTopElements,
    UpdateDocumentHeader
  ],
  mounted() {
    this.$nextTick(function () {
      // Code that will run only after the
      // entire view has been rendered
      this.updateDocumentHeader( this.$tl('links.imprint'), this.$tl('imprint.responsible') + ' ' + this.$tl('imprint.address').replaceAll('\n',', ') )
    })
    window.addEventListener('scroll', () => this.scrollHandling());
  },
  data() {
    return {
      elemsToForceUpdate: [
        'menubar',
        'start',
        'entries',
        'entry',
        'entryNotFound',
        'footer'
      ],
      entryChosen: false,
      entryValid: false,
      chosenEntry: null,
      tagChosen: false,
      tagValid: false,
      chosenTag: null
    }
  },
  computed: {
  },
  inject: ['i18n','currentRoute'],
  methods: {
  }
}
</script>

<style>
</style>
