<script>
export default {
  name: 'AnimateTopElements',
  data() {
    return {
      minimizeTopElementsAbove: .05,
      topElementStyle: 'normal',
    }
  },
  mounted() {
    window.addEventListener('scroll', () => this.scrollHandling());
    //console.log('currentRoute',this.currentRoute)
    // this.selectedLanguage = this.i18n.locale
    //console.log( 'locale stuff',this.i18n.locale, this.selectedLanguage, this.$tl('buttons.back'))
  },
  methods: {
    scrollHandling() {
      // console.log('scroll position:', window.scrollY, this.minimizeTopElementsAbove, window.scrollY >= this.minimizeTopElementsAbove)
      this.topElementStyle = window.scrollY >= this.minimizeTopElementsAbove * window.innerHeight ? 'compact' : 'normal'
    }
  }

}
</script>