<template >
  <MenuBar ref="menubar" :menuItems="menuLinks" :languages="languages" :initSelectedLanguage="selectedLanguage" @update-selected-language="updateSelectedLanguage"/>
  <BookNotFound v-if="entryChosen && !entryValid" ref="bookNotFound" />

    <div v-if="entryChosen && entryValid" :key="randomKey" id="books" :class="`container-fluid mb-50`">
      <div class="container flex-row content-wrap content-center align-start ">
        <div :class="`col-12 `">
          <h1 class="text-hover-shadow">{{ chosenEntry.title }}</h1>
          <span class="divider"></span>
        </div>
        <div :class="`mt-50 col-12 ${chosenEntry.type == 'poem' ? 'col-md-10 col-lg-8' : ''}` ">
          <div :class="`book-quote hover-shadow-big smooth-transition-fast  ${chosenEntry.type}`">
            {{ chosenEntry.quote }}
          </div>
        </div>
      </div>
      <div class="mt-100 container flex-row content-wrap content-center align-center ">
        <div class="col-md-4 col-12 flex-column content-center align-center">
          <img class="smooth-transition-fast hover-shadow-big" style="width: 100%" :src="getImagePath( chosenEntry.image,chosenEntry.imagetype)" />
          <span class="image-right mt-10" v-html="chosenEntry.imageRight"></span>
        </div>
        <div class="col-md-8 col-12 flex-column content-center align-center book-description-right" v-html="chosenEntry.descriptionLong">
        </div>
      </div>
    </div>
    <div v-if="entryChosen && entryValid && chosenEntry.hasShopLinks" :key="randomKey" class="container-fluid pt-50 pb-50" id="books-shop-links">
      <div class="container flex-row content-wrap content-center align-center">
        <div class="col-12 flex-column content-center align-center">
          <h2 class="text-hover-shadow">{{ $tl('books.shopTitle') }}</h2>
          <span class="divider-light"></span>
        </div>
        <div v-for="type in chosenEntry.shop.types" :key="type" class="col-md-6 col-lg-4 col-12 mt-20">
          <div v-html="getBookShopWidgetElement( chosenEntry.shop.key, type, chosenEntry.shop.shop )"></div>
          
          <!-- shop: {
            key: '356316',
            types: [ 'ebook', 'print' ]
          } -->
        </div>
        <!-- <div class="col-md-6 col-lg-4 col-12 mt-20">
          <div v-html="chosenEntry.shopHardcover"></div>
        </div>
        <div class="col-md-6 col-lg-4 col-12 mt-20">
          <div v-html="chosenEntry.shopEbook"></div>
        </div> -->
      </div>
    </div>
  <Footer ref="footer" :tags="blog.tags" :social="socialLinks"/>
</template>

<script>
import Footer from '../components/FooterComponent.vue'
import MenuBar from '../components/MenuBarComponent.vue'
import BookNotFound from '../components/BookNotFound.vue'

import DataDefinitions from '../mixins/DataDefinitions.vue'
import LanguageSwitching from '../mixins/LanguageSwitching.vue'
import AnimateTopElements from '../mixins/AnimateTopElements.vue'
import GenerateImagePaths from '../mixins/GenerateImagePaths.vue'
import UpdateDocumentHeader from '../mixins/UpdateDocumentHeader.vue'
import BookShopWidgets from '../mixins/BookShopWidgets.vue'

export default {
  name: 'Books',
  components: {
    MenuBar,
    BookNotFound,
    Footer
  },
  mixins: [
    //GenerateImagePaths
    DataDefinitions,
    LanguageSwitching,
    AnimateTopElements,
    GenerateImagePaths,
    UpdateDocumentHeader,
    BookShopWidgets
  ],
  mounted() {
    this.$nextTick(function () {
      // Code that will run only after the
      // entire view has been rendered
      if (  this.entryChosen && this.entryValid ) {
        this.updateDocumentHeader( this.chosenEntry.title, this.chosenEntry.quote.replaceAll('\n',' ') )
      } else {
        this.updateDocumentHeader( this.$tl('links.books'), this.$tl('books.homeIntro') )
      }
      if ( this.entryChosen && this.entryValid && this.chosenEntry.hasShopLinks ) {
        this.addBookShopWidgetsScript( this.chosenEntry.shop.key, this.chosenEntry.shop.types, this.chosenEntry.shop.shop )
      }
    })
    window.addEventListener('scroll', () => this.scrollHandling());
    this.checkEntry()
    // console.log('chosen Entry: ',this.chosenEntry)
  },
  data() {
    return {
      elemsToForceUpdate: [
        'menubar',
        'bookNotFound',
        'footer'
      ],
      entryChosen: false,
      entryValid: false,
      chosenEntry: null
    }
  },
  computed: {
  },
  inject: ['i18n','currentRoute'],
  methods: {
    checkEntry() {
      // console.log('checkEntry', this.currentRoute, this.currentRoute.length, this.currentRoute.length > 3, window.location.search)
      if ( this.currentRoute.length > 3 ){
        // console.log('entry chosen?', this.currentRoute)
        this.entryChosen = true
        for ( var entry of this.bookList ) {
          if ( entry.url == this.currentRoute[3] ){
            this.entryValid = true
            this.chosenEntry = entry
            break
          }
        }
      } 
    }
  }
}
</script>

<style>
</style>
