<template>
  <!--cookie banner-->
  <div ref="cookie-banner" id="cookie-banner" class="hidden smooth-transition">
      <p>{{ $tl('cookie.text') }}</p>
      <a class="button-link-dark hover-shine hover-scale-little smooth-transition-fast" @click="consentCookie()">{{ $tl('cookie.ok') }}</a>
      <a class="button-link-dark hover-shine hover-scale-little smooth-transition-fast" @click="rejectCookie()">{{ $tl('cookie.no') }}</a>
      <a class="button-link-dark hover-shine hover-scale-little smooth-transition-fast" :href="getLanguageLink($tl('links.imprintUrl'))">{{ $tl('cookie.moreInfo') }}</a>
      <a class="close-cross" @click="consentCookie()">X</a>
  </div>
</template>

<script>
import GenerateImagePaths from '../mixins/GenerateImagePaths.vue'
import LanguageSwitching from '../mixins/LanguageSwitching.vue'

export default {
  name: 'CookieBanner',
  emits: [],
  props: {
  },
  mounted() {
    this.$nextTick(function () {
      // Code that will run only after the
      // entire view has been rendered
      window.setTimeout( this.checkCookieConsent, 1000 )
    })
  },
  data() {
    return {
    }
  },
  components: {
  },
  mixins: [
    GenerateImagePaths,
    LanguageSwitching
  ],
  methods: {
      consentCookie() {
        // console.log('YES')
        window.Matomo.getTracker().rememberCookieConsentGiven( 24*30 )
        this.closeMe()
        document.cookie = 'hasRejected=true; expires=Thu, 01 Jan 1970 00:00:00 UTC'
      },
      rejectCookie() {
        // console.log('NO')
        window.Matomo.getTracker().forgetCookieConsentGiven()
        document.cookie = 'hasRejected=true'
        this.closeMe()
      },
      closeMe(){
        // console.log('close me')
        this.$refs['cookie-banner'].classList.add('hidden')
      },
      checkCookieConsent() {
        // console.log( 'cookie consent:', window.Matomo.getTracker().getRememberedCookieConsent(), this.$refs['cookie-banner'].classList, document.cookie )
          if( window.Matomo.getTracker().getRememberedCookieConsent() == 0 && !document.cookie.includes('hasRejected=true') ) {
            this.$refs['cookie-banner'].classList.remove('hidden')
          }
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>