import { createApp, h } from 'vue'
import routes from './routes'
/* import VueI18n from 'vue-i18n' */
import de from './locale/de';
import en from './locale/en';
/* import App from './App.vue' */


import TranslatePlugin from './plugins/TranslatePlugin' 
const availableLanguages = [ 'de', 'en' ]

const SimpleRouter = {
    data: () => ({
        currentRoute: window.location.pathname.split('/')
    }),

    computed: {
      ViewComponent () {
        const matchingPage = routes['/'+(this.currentRoute[2] ? this.currentRoute[2] : '')] || '404'
        return require(`./pages/${matchingPage}.vue`).default
      }
    },
    
    methods: {
      checkURL() {
        /* console.log('checking URL', this.currentRoute, typeof this.currentRoute[2],availableLanguages.includes(this.currentRoute[1])) */
        var redirect = false

        // url has only one part and it is no language 
        if ( typeof this.currentRoute[2] == 'undefined' && !availableLanguages.includes(this.currentRoute[1]) ) {
          redirect = true
          this.currentRoute.push(this.currentRoute[1])
          this.currentRoute[1] = availableLanguages[0]
        }

        if ( !availableLanguages.includes(this.currentRoute[1]) ) {
          redirect = true
          if ( typeof this.currentRoute[2] == 'undefined' ) {
            /* console.log('no part 2 and part 1 no language') */
            this.currentRoute.push(this.currentRoute[1])
            this.currentRoute[1] = availableLanguages[0]
          } else {
            /* console.log('redirecting to default language!', this.currentRoute) */
            this.currentRoute[1] = availableLanguages[0]
            if ( !this.currentRoute[2] )
              this.currentRoute[2] = ''
          }
        } else if ( typeof this.currentRoute[2] == 'undefined' ) {
          /* console.log('no part 2 and part 1 IS language') */
          this.currentRoute.push('')
          redirect = true
        }

        if ( redirect ) {
          const newURL = 
          window.location.protocol+'//'
          + window.location.hostname
          + (window.location.port != '' ? ':'+window.location.port : '') 
          + this.currentRoute.join('/')
          + window.location.search
          + window.location.hash
          console.log('redirecting to ', newURL)
          window.location.assign( newURL )
        }

        return true
      }
    },

    render () {
      return h(this.ViewComponent)
    },
  
    created () {
      this.checkURL()
      window.addEventListener('popstate', () => {
        //console.log('popstate event fired', event.state)
        this.currentRoute = window.location.pathname.split('/')
      })
    },
    provide() {
      return {
        currentRoute: this.currentRoute
      }
    }
    
}

const app = createApp(SimpleRouter)
/* const i18nStrings = { locale: 'de', messages: {de,en} }
app.use(i18nPlugin, i18nStrings) */
/* console.log('SR.data',SimpleRouter.data().currentRoute, typeof SimpleRouter.data())
console.log(window.location.pathname.split('/')) */
const chosenLanguage = availableLanguages.includes(window.location.pathname.split('/')[1]) ? window.location.pathname.split('/')[1] : availableLanguages[0]
/* console.log('chosenLang:', chosenLanguage) */
app.use(TranslatePlugin,{locale: chosenLanguage, locales: {de,en}}) 
app.mount('#app')
