<template>
  <!-- welcome -->
    <div :key="randomKey" id="blog-entry" :class="`container-fluid`">
      <div class="container flex-row content-wrap content-center align-center ">
        <template v-if="entry.type == 'poem'">
          <div :class="`col-12 flex-column content-center align-center type-${entry.type}`">
            <img class="entry-image hover-shadow-big smooth-transition-fast" :src="getImagePath( entry.image, entry.imagetype )" />
            <h1>{{ entry.title }}</h1>
            <span :class="`blog-card-entry-date type-${entry.type}`">{{ getDateString(entry.entryDate) }}</span>
            <span class="divider"></span>
            <div :class="`blog-card-description hover-shadow-big smooth-transition-fast type-${entry.type}`" v-html="entry.text"></div>
            <span :class="`blog-card-entry-copyright type-${entry.type}`">&copy; {{ $tl('global.author') }}, {{ getDateString(entry.date) }}</span>
            <span class="divider"></span>
            <span class="image-right" v-html="`<i>${$tl('global.titleImageRight')}:</i> `+entry.imageRight"></span>
          </div>
        </template>
        <template v-if="entry.type == 'text'">
          <div :class="`col-12 flex-column content-center align-center type-${entry.type}`">
            <img class="entry-image hover-shadow-big smooth-transition-fast" :src="getImagePath( entry.image, entry.imagetype )" />
            <h1>{{ entry.title }}</h1>
            <span :class="`blog-card-entry-date type-${entry.type}`">{{ getDateString(entry.entryDate) }}</span>
            <span class="divider"></span>
            <div :class="`blog-card-description hover-shadow-big smooth-transition-fast type-${entry.type}`" v-html="entry.text"></div>
            <span :class="`blog-card-entry-copyright type-${entry.type}`">&copy; {{ $tl('global.author') }}, {{ getDateString(entry.date) }}</span>
            <span class="divider"></span>
            <span class="image-right" v-html="`<i>${$tl('global.titleImageRight')}:</i> `+entry.imageRight"></span>
          </div>
        </template>
        <div v-if="entry.tags.length > 0" :class="`col-12 flex-column content-center align-center type-${entry.type} blog-entry-tags  hover-shadow-big smooth-transition-fast`">
          <h2 class="col-12 blog-entry-tags-title">
            {{ $tl('blog.tags') }}
          </h2>
          <div class="flex-row content-center align-center col-12 content-wrap">
            <span 
              v-for="tag in entry.tags"
              :key="tag"
              class="blog-entry-tag col-6 col-sm-4 col-md-3 col-lg-2">
              <a class="button-link hover-shadow hover-scale-little smooth-transition-fast" :href="getLanguageLink('/blog?tag='+tag)">{{ $tl(tags[tag]) }}</a>
            </span>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import GenerateImagePaths from '../mixins/GenerateImagePaths.vue'
import LanguageSwitching from '../mixins/LanguageSwitching.vue'

export default {
  name: 'BlogEntry',
  emits: [],
  props: {
    entry: Object,
    tags: Object
  },
  data() {
    return {
    }
  },
  computed: {
    randomKey: function() {
      return this.randPrefix+'-'+this.randPart
    }
  },
  components: {
  },
  mixins: [
    GenerateImagePaths,
    LanguageSwitching
  ],
  methods: {
    getDateString( dateStr ) {
      var myDateStr = new Date(dateStr)
      //console.log(dateStr, myDateStr)
      return myDateStr.toLocaleString('de-DE', {day: 'numeric', month: 'long', year: 'numeric'} )
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>