<template >
  <MenuBar ref="menubar" :menuItems="menuLinks" :languages="languages" :initSelectedLanguage="selectedLanguage" @update-selected-language="updateSelectedLanguage"/>
  <BlogStart v-if="!entryChosen" ref="start" :count="filteredBlogEntries.length" :hasTag="tagChosen && tagValid" :tag="chosenTag" :tags="blog.tags"/>
  <BlogEntries v-if="!entryChosen" ref="entries" :entries="filteredBlogEntries"/>
  <BlogEntry v-if="entryChosen && entryValid" :entry="chosenEntry" :tags="blog.tags" ref="entry" />
  <BlogEntryNotFound v-if="somethingChosen && !somethingValid" ref="entryNotFound" />
  <Footer ref="footer" :tags="blog.tags" :social="socialLinks"/>
</template>

<script>
import Footer from '../components/FooterComponent.vue'
import MenuBar from '../components/MenuBarComponent.vue'
import BlogStart from '../components/BlogStart.vue'
import BlogEntries from '../components/BlogEntries.vue'
import BlogEntry from '../components/BlogEntry.vue'
import BlogEntryNotFound from '../components/BlogEntryNotFound.vue'

import DataDefinitions from '../mixins/DataDefinitions.vue'
import LanguageSwitching from '../mixins/LanguageSwitching.vue'
import AnimateTopElements from '../mixins/AnimateTopElements.vue'
import UpdateDocumentHeader from '../mixins/UpdateDocumentHeader.vue'

export default {
  name: 'Blog',
  components: {
    MenuBar,
    BlogStart,
    BlogEntries,
    BlogEntry,
    BlogEntryNotFound,
    Footer
  },
  mixins: [
    //GenerateImagePaths
    DataDefinitions,
    LanguageSwitching,
    AnimateTopElements,
    UpdateDocumentHeader
  ],
  mounted() {
    this.$nextTick(function () {
      // Code that will run only after the
      // entire view has been rendered
      if ( this.entryChosen && this.entryValid ) {
        this.updateDocumentHeader( this.chosenEntry.title, this.chosenEntry.intro )
      } else {
        var blogTitle = this.$tl('links.blog') + (this.tagChosen && this.tagValid ? ` [${this.$tl(this.blog.tags[this.chosenTag])}]` : '')
        var blogDescription = `${this.filteredBlogEntries.length} ${this.$tl('blog.count') } ${ this.tagChosen && this.tagValid ? this.$tl('blog.tagChosen')+': '+this.$tl(this.blog.tags[this.chosenTag]) : ''  }`
        this.updateDocumentHeader( blogTitle, blogDescription  )
      }
    })
    window.addEventListener('scroll', () => this.scrollHandling());
    // console.log('entries',this.blogEntries.length, 'route', this.currentRoute, 'entryChosen',this.entryChosen, 'entryValid', this.entryValid, 'chosenEntry', this.chosenEntry)
    this.checkEntry()
    // console.log('entries',this.blogEntries.length, 'route', this.currentRoute, 'entryChosen',this.entryChosen, 'entryValid', this.entryValid, 'chosenEntry', this.chosenEntry)
  },
  data() {
    return {
      elemsToForceUpdate: [
        'menubar',
        'start',
        'entries',
        'entry',
        'entryNotFound',
        'footer'
      ],
      entryChosen: false,
      entryValid: false,
      chosenEntry: null,
      tagChosen: false,
      tagValid: false,
      chosenTag: null
    }
  },
  computed: {
    somethingChosen: function() {
      return this.entryChosen || this.tagChosen
    },
    somethingValid: function() {
      return this.entryValid || this.tagValid
    },
    filteredBlogEntries: function(){
      return this.tagChosen && this.tagValid ? this.blogEntries.filter( entry => entry.tags.includes(this.chosenTag)) : this.blogEntries
    }
  },
  inject: ['i18n','currentRoute'],
  methods: {
    checkEntry() {
      // console.log('checkEntry', this.currentRoute, this.currentRoute.length, this.currentRoute.length > 3, window.location.search)
      if ( this.currentRoute.length > 3 ){
        // console.log('entry chosen?', this.currentRoute)
        this.entryChosen = true
        for ( var entry of this.blogEntries ) {
          if ( entry.url == this.currentRoute[3] ){
            this.entryValid = true
            this.chosenEntry = entry
            break
          }
        }
      } else if ( window.location.search.match(/((\w)=(\w))/ig) ) {
        var matches = window.location.search.matchAll(/(([a-z-]+)=([a-z-]+))+/ig)
        // console.log('search found!', matches)
        for ( var match of matches ) {
          // console.log('match: ',match)
          // var innerMatch = match.match(/^([a-z\-]+)=([a-z\-]+)$/ig)
          // console.log('innerMatch: ',innerMatch)
          if ( match[2] == 'tag' ){
            // console.log('tag found')
            this.tagChosen = true
            if ( this.blog.tags[match[3]] ) {
              // console.log('tag matched!!!')
              this.tagValid = true
              this.chosenTag = match[3]
              break
            }
          }
        }
      }
    }
  }
}
</script>

<style>
</style>
