<template >
  <MenuBar ref="menubar" :menuItems="menuLinks" :languages="languages" :initSelectedLanguage="selectedLanguage" @update-selected-language="updateSelectedLanguage"/>
  <Welcome ref="welcome" />
  <Books ref="books" :books="bookList" />
  <Blog ref="blog" :entries="blogEntries"/>
  <Footer ref="footer" :tags="blog.tags" :social="socialLinks"/>
</template>

<script>
import Footer from '../components/FooterComponent.vue'
import MenuBar from '../components/MenuBarComponent.vue'
import Welcome from '../components/StartpageWelcome.vue'
import Books from '../components/StartpageBooks.vue'
import Blog from '../components/StartpageBlog.vue'

import DataDefinitions from '../mixins/DataDefinitions.vue'
import LanguageSwitching from '../mixins/LanguageSwitching.vue'
import AnimateTopElements from '../mixins/AnimateTopElements.vue'
import UpdateDocumentHeader from '../mixins/UpdateDocumentHeader.vue'
import AnimateParallaxElements from '../mixins/AnimateParallaxElements.vue'

export default {
  name: 'Home',
  components: {
    MenuBar,
    Welcome,
    Books,
    Blog,
    Footer
  },
  mixins: [
    //GenerateImagePaths
    DataDefinitions,
    LanguageSwitching,
    AnimateTopElements,
    UpdateDocumentHeader,
    AnimateParallaxElements
  ],
  mounted() {
    this.$nextTick(function () {
      // Code that will run only after the
      // entire view has been rendered
      this.updateDocumentHeader( this.$tl('links.start'), this.$tl('welcome.text').replaceAll('\n',' ').replaceAll('  ', ' ') )
    })
    window.addEventListener('scroll', () => this.scrollHandling());
  },
  data() {
    return {
      elemsToForceUpdate: [
        'menubar',
        'welcome',
        'books',
        'blog',
        'footer'
      ]
    }
  },
  inject: ['i18n','currentRoute'],
  methods: {
  }
}
</script>

<style>
</style>
