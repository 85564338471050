<template>
  <!-- menubar -->
    <div :key="randomKey" id="menubar" :class="`menubar p-10 ${topElementStyle}`" >
      <div class="container-fluid">
        <div class="flex-row content-start align-center menubar-wrapper">
          <div class="flex-row content-start align-center site-title col-10 col-md-6">
            <a class="" :href="getLanguageLink('/#top')">
              <img class="smooth-transition hover-scale" :src="topElementStyle == 'normal' ? getImagePath('mh-new-2','svg') : getImagePath('mh-new-2-light','svg')" />
            </a>
            <div class="site-title flex-column col-md-10">
              <span class="title smooth-transition text-hover-shine">{{ $tl('global.title') }}</span>
              <span class="subtitle smooth-transition text-hover-shine">{{ $tl('global.subtitle') }}</span>
            </div>
          </div>
          <div class="flex-row content-end align-center col-12 col-md-5 menu">
            <a v-for="item of menuItems" 
              :key="item.id" class="menu-item smooth-transition"
              :href="getLanguageLink($tl(item.url))">
                {{ $tl(item.text) }}
                <img v-if="item.hasChildren" :src="getImagePath('caret-down-light','svg')" class="smooth-transition"/>
                <div class="submenu flex-colum content-center align-center" v-if="item.hasChildren">
                  <div v-for="subItem of item.children" 
                    :key="subItem.id" class="menu-item smooth-transition">
                    <a
                    :href="getLanguageLink($tl(subItem.url))">
                      {{ $tl(subItem.text) }}
                  </a>
                  </div>
                </div>
              </a>
          </div>
          <ul :class="`language-switcher smooth-transition`">
              <li role="option" 
                v-for="language of languages"
                :key="language.id"
                :aria-selected="selectedLanguage == language.id"
                @click.prevent="selectedLanguage = language.id">
                  <a href="#" class="elm-link " title="English language" rel="alternate" :hreflang="language.id">
                    {{ language.title }}
                    <img class="smooth-transition" v-show="selectedLanguage == language.id" :src="getImagePath( 'caret-down-light', 'svg' )" />
                  </a>
              </li>
          </ul>
        </div>
      </div>

    </div>
</template>

<script>
import GenerateImagePaths from '../mixins/GenerateImagePaths.vue'
import AnimateTopElements from '../mixins/AnimateTopElements.vue'
import LanguageSwitching from '../mixins/LanguageSwitching.vue'

export default {
  name: 'Headline',
  props: {
    menuItems: Array,
    initSelectedLanguage: String,
    languages: Array
  },
  data() {
    return {
      randPrefix: 'topBar',
      selectedLanguage: 'de'
    }
  },
  computed: {
    randomKey: function() {
      return this.randPrefix+'-'+this.randPart
    }
  },
  created() {
    this.selectedLanguage = this.initSelectedLanguage
  },
  watch: {
    /* deep: true,
    immediate: true, */
    selectedLanguage() {
      this.$emit( 'updateSelectedLanguage', { value: this.selectedLanguage} )
    },
    initSelectedLanguage( newValue ) {
      // console.log('initSelectedLanguage changed to', this.initSelectedLanguage)
      this.selectedLanguage = newValue
    }
  },
  components: {
  },
  mixins: [
    GenerateImagePaths,
    AnimateTopElements,
    LanguageSwitching
  ],
  emits: ['updateSelectedLanguage'],
  methods: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
