<template>
  <!-- welcome -->
    <div :key="randomKey" id="home-blog" :class="`container-fluid`">
      <div class="container flex-row content-wrap content-center align-start home-container">
        <div class="col-12 home-container-centered">
          <h2 class="text-hover-shadow">{{ $tl('blog.homeTitle') }}</h2>
          <span class="divider"></span>
        </div>
        <div v-for="entry in myEntries"
          :key="entry.id"
          class="col-12 col-md-4 blog-card hover-shadow-big smooth-transition-fast">
          <template v-if="entry.type == 'poem' || entry.type == 'text'">
            <div :class="`blog-card-image type-${entry.type}`">
              <img class="hover-shadow-big smooth-transition-fast" :src="getImagePath( entry.image, entry.imagetype )" />
              <span class="image-right" v-html="'<i>Bild:</i> '+entry.imageRight"></span>
            </div>
            <div :class="`blog-card-title type-${entry.type}`">
              <h3>{{ entry.title }}</h3>
              <span :class="`blog-card-entry-date type-${entry.type}`">{{ getDateString(entry.entryDate) }}</span>
            </div>
            <div :class="`blog-card-description type-${entry.type}`" v-html="entry.intro"></div>
            <!-- <div :class="`blog-card-morelink type-${entry.type}`">
              <a class="button-link hover-shadow hover-scale-little smooth-transition-fast" :href="getLanguageLink(entry.url)">
                {{ $tl('blog.moreLink') }}
                <img class="button-link-icon" :src="getImagePath('arrow-right', 'svg')" />
              </a>
            </div> -->
            <div :class="`blog-card-morelink type-${entry.type}`">
              <a class="smooth-transition-fast" :href="getLanguageLink('/blog/'+entry.url)">
                {{ $tl('blog.moreLink') }}
                <img class="link-icon" :src="getImagePath('arrow-right', 'svg')" />
              </a>
            </div>
          </template>
        </div>
        <div class="col-12 home-container-centered">
          <span class="divider"></span>
          <a class="button-link hover-shadow hover-scale-little smooth-transition-fast" :href="getLanguageLink('/blog')">
            {{ $tl('blog.allEntries') }}
            <img class="link-icon" :src="getImagePath('arrow-right', 'svg')" />
          </a>
        </div>
      </div>
    </div>
</template>

<script>
import GenerateImagePaths from '../mixins/GenerateImagePaths.vue'
import LanguageSwitching from '../mixins/LanguageSwitching.vue'

export default {
  name: 'StartpageBlog',
  emits: [],
  props: {
    entries: Array
  },
  data() {
    return {
    }
  },
  computed: {
    randomKey: function() {
      return this.randPrefix+'-'+this.randPart
    },
    myEntries: function() {
      var myEnts = new Array(...this.entries)
      // console.log('myEntries called')
      myEnts.sort((a,b) => {
        return a.entryDate == b.entryDate ? 0 : (a.entryDate < b.entryDate ? 1 : -1 )
      })
      // console.log(myEnts)
      return myEnts.slice(0,3)
    }
  },
  components: {
  },
  mixins: [
    GenerateImagePaths,
    LanguageSwitching
  ],
  methods: {
    getDateString( dateStr ) {
      var myDateStr = new Date(dateStr)
      //console.log(dateStr, myDateStr)
      return myDateStr.toLocaleString('de-DE', {day: 'numeric', month: 'long', year: 'numeric'} )
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>