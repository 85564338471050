export default {
    install: (app, options) => {
        //app.config.globalProperties.locale = options.locale
        //app.config.globalProperties.locales = options.locales
        app.config.globalProperties.$tl = (key) => {
            return key.split('.')
            .reduce((o, i) => { if (o) return o[i] }, options.locales[options.locale])
        }
        app.provide('i18n', options)
    }
}