export default {
    '/': 'Home',
    '/home': 'Home',
    '/start': 'Home',
    '/blog': 'Blog',
    '/buecher': 'Books',
    '/books': 'Books',
    '/about-me': 'AboutMe',
    '/ueber-mich': 'AboutMe',
    '/impressum': 'Imprint',
    '/imprint': 'Imprint'
}