<script>
export default {
    name: 'UpdateLanguageUrl',
    data() {
      return {
        elemsToForceUpdate: [],
        selectedLanguage: 'de',
        randPrefix: 'randKey',
        randPart: (new Date()).getMilliseconds()
      }
    },
    inject: ['i18n'],
    computed: {
      randomKey: function() {
        return this.randPrefix+'-'+this.randPart
      }
    },
    mounted() {
      this.selectedLanguage = this.i18n.locale
      
      window.addEventListener('popstate', (event) => {
        // console.log('popstate event fired (mixin)', event.state,window.location.pathname.split('/'))
        this.updateSelectedLanguage( event.state ? event.state : {value: window.location.pathname.split('/')[1]}, false)
      })
    },
    methods: {
      updateSelectedLanguage( data, updateUrl = true ) {
        // update language switcher
        this.selectedLanguage = data.value
        // update locale plugin
        this.i18n.locale = data.value
        // workaround for top elements not changing language
        this.showTopElements = this.showTopElements == false
        /* this.$nextTick(function () {
          this.showTopElements = this.showTopElements == false
        }) */
        // forcing elements to re-render
        for ( var elem of this.elemsToForceUpdate ) {
          // console.log('forcing update of ', elem)
          if ( this.$refs[elem] && typeof this.$refs[elem].$forceUpdate == 'function' ) {
            this.$refs[elem].$forceUpdate()
          }
          // this.$refs[elem]?.$forceUpdate?.()
          // call the method of the element do update children
          if ( this.$refs[elem] && typeof this.$refs[elem].forceKeyUpdate == 'function' ) {
            this.$refs[elem].forceKeyUpdate()
          }
          // this.$refs[elem]?.forceKeyUpdate?.()
          if ( this.$refs[elem] && typeof this.$refs[elem].forceChildUpdate == 'function' ) {
            this.$refs[elem].forceChildUpdate()
          }
          // this.$refs[elem]?.forceChildUpdate?.()
        }
        // update the URL shown to the user
        if( updateUrl ) {
          // console.log('update the url')
          this.updateURL( data.value )
        }
      },
      updateURL( newLang ) {
        console.log('updateUrl called with', newLang)
        var newURL = window.location.pathname.split('/')
        newURL[1] = newLang
        history.pushState( { value: this.i18n.locale }, '', newURL.join('/') + window.location.search + window.location.hash )
      },
      getLanguageLink( url ) {
        return url.startsWith('/') ? `/${this.i18n.locale}${url}` : url
      },
      forceKeyUpdate() {
        // console.log('fcu called', this.randPart, this.randomKey)
        this.randPart = (new Date()).getMilliseconds()
        // console.log('--', this.randPart, this.randomKey)
      }
    }

}
</script>