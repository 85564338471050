<template>
  <CookieBanner ref="cookies" :key="randomKey"/>
  <!--footer-->
  <footer id="footer">
    <div class="container">
      <div class="flex-row content-between align-start content-wrap">
        <div class="col-lg-2 col-md-3 col-6">
          <b class="footer-title">{{ $tl('global.moreLinks') }}</b>
          <ul>
            <li>
              <a :href="getLanguageLink($tl('links.imprintUrl'))">{{ $tl('links.imprint') }}</a>
            </li>
          </ul>
        </div>
        <div class="site-title flex-column col-lg-2 col-md-3 col-6">
          <b class="footer-title">{{ $tl('blog.tags') }}</b>
          <ul>
            <li v-for="(title, id) in tags"
             :key="id">
             <a :href="getLanguageLink($tl('links.blogUrl')+'?tag='+id)">{{ $tl(title) }}</a>
            </li>
          </ul>
        </div>
        <div class="site-title flex-column col-lg-2 col-md-3 col-6 social">
          <b class="footer-title">{{ $tl('global.social') }}</b>
          <div>
            <a  v-for="soc in social"
            :key="soc.id"
            :class="soc.id " 
            :href="soc.url">
              <img class="smooth-transition-fast hover-scale hover-shine" :title="soc.title" :src="getImagePath( soc.id+'-light','svg')" />
            </a>
          </div>
        </div>
        <div class="col-lg-2 col-md-3 col-6 top-button">
          <b class="footer-title">{{ $tl('buttons.toTop') }}</b>
          <a href="#top" class=""><img class="smooth-transition-fast hover-scale hover-shine" :title="$tl('buttons.toTop')" :src="getImagePath('arrow-up-light','svg')" /></a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import CookieBanner from './CookieBannerComponent.vue'

import GenerateImagePaths from '../mixins/GenerateImagePaths.vue'
import LanguageSwitching from '../mixins/LanguageSwitching.vue'

export default {
  name: 'Footer',
  emits: ['innerLinkClicked'],
  props: {
    tags: Object,
    social: Array
  },
  data() {
    return {
    }
  },
  components: {
    CookieBanner
  },
  mixins: [
    GenerateImagePaths,
    LanguageSwitching
  ],
  methods: {
      linkClicked( linkType, linkId ) {
          if ( linkType == 'inner' ) {
              this.$emit('innerLinkClicked', { linkId })
          }
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>