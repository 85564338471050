/** 
 * german language file 
 * 
 * List of placeholders:
 * {example} - This is an example
 */

 export default {
    buttons: {
        back: 'Back',
        open: 'Open',
        toTop: 'Back to top'
    },
    global: {
        title: 'Michael Hitzelberger',
        subtitle: 'Poems, books and more',
        moreLinks: 'More links',
        imprint: 'Imprint',
        social: 'Social media',
        imageRight: 'Image',
        titleImageRight: 'Title image',
        author: 'Michael Hitzelberger'
    },
    links: {
        start: 'Home',
        blog: 'Blog', 
        books: 'Books', 
        keinhorn: 'Das Keinhorn', 
        albertAmeise: 'Albert Ameise', 
        aboutMe: 'About me',
        imprint: 'Imprint',
        startUrl: '/home',
        blogUrl: '/blog',
        booksUrl: '/home#home-books',
        booksUrlPart: '/books/',
        keinhornUrl: '/books/das-keinhorn',
        albertAmeiseUrl: '/books/albert-ameise',
        aboutMeUrl: '/about-me',
        imprintUrl: '/imprint'
    },
    sticker: {
        newBlogEntry: 'New blog entry'
    },
    welcome: {
        title: 'WELCOME',
        subtitle: 'to my website!',
        text: 'Here I publish my poems,\npresent my books,\nand show you what else I do,\nwhat is on my mind.\n\nHave fun looking around!'
    },
    books: {
        homeTitle: 'Current book projects',
        homeIntro: 'This is where I present you my current book projects.\n',
        moreLink: 'More information on the book',
        notFound: 'Book not found',
        notFoundText: 'Unfortunately we could not find a book belonging to this URL.',
        lookHere: 'You can find my books here:',
        shopTitle: 'Bookstore links'
    },
    blog: {
        title: 'Blog',
        count: 'entries',
        tagChosen: 'with tag',
        moreEntries: 'More blog entries',
        showMore: 'Show more entries',
        tags: 'Tags',
        homeTitle: 'Newest blog entries',
        moreLink: 'Read more',
        allEntries: 'View all blog entries',
        notFound: 'Blog entry not found',
        notFoundText: 'Unfortunately we could not find an entry belonging to this URL.',
        lookHere: 'You can find my blog here:'
    },
    tags:{
        poem: 'Poems',
        german: 'German',
        english: 'English',
        limerick: 'Limericks',
        hommage: 'Hommage',
        shortstory: 'Shortstory'
    },
    notFound: {
        title: 'Page not found',
        text: 'Unfortunately we could not find any content belonging to this URL.',
        toStartpage: 'Find more on the'
    },
    imprint: {
        title: 'Imprint',
        responsible: 'Responsible for the website\'s content and data security:',
        address: 'Michael Hitzelberger\nPostweg 2\n74858 Aglasterhausen',
        mail: 'mail@mhitzelberger.de',
        whoWeAre: 'Who we are',
        whoWeAreText: 'Our webseite\'s address is:',
        whoWeAreUrl: 'https://mhitzelberger.de',
        data: 'Data security',
        dataText: 'With the following data security declaration we want to inform you about the types of personal data we are processing for which purposes. This applies for all processes where personal data is involved, both on our websites and on other online representations like social media profiles (e.g. Facebook or Instagram).',
        cookies: 'Cookies',
        cookiesText1: 'Cookies are text files containing data from visited websites or domains and are stored on the user\'s computer. A cookie is used to store information about the user during or after they visited a website. This data can contain the browser\'s language setting the user\'s login status, a cart or the position of a viewed video.',
        cookiesText2: 'This website uses cookies to track your interactions with our content. No data is handed over or given access to third parties. You can reject the use of cookies by clicking "no" in the cookie dialog on this website. Furthermore, we respect the "do not track" settings of your browser; should it be activated, no usage data is being stored.',
        otherSites: 'Embedded content from other websites',
        otherSitesText1: 'Postings on this webistes may contain embedded content (e.g. videos, images). Embedded content from other website behaves as if you would visith the respective website.',
        otherSitesText2: 'These websites may collect data about you, use cookies, embed tracking tools from third party sites that track your inteactions with this embedded content.'
    },
    aboutMe: {
        title: 'About me',
        text0: 'I have a computer scientist diploma (bioinformatics) and I am living in Aglasterhausen with my family. Currently, I am working at DB Cargo AG as Manager IT Development',
        text1: 'In my spare time I am writing poems and stories and I am a <a href="http://kandelschiffer.de/">Kandelschiffer</a> at the „Echt Mosbacher Fassenacht“ of the <a href="https://www.kolping-mosbach.de/echt-mosbacher-fassenacht/">Kolpingsfamilie Mosbach</a>.',
        text2: 'I wrote bed-time stories for my two daughters about „Albert Ameise“ (Albert the ant) and am currently working on publishing these as a book. Last year I published a book about a unicorn without a horn - „Das Keinhorn“ - which is a rhymed story about not being accepted for being different. For more information see:',
        
        text3: 'On this website I will also publish content about other topics I am interested in.'
    },
    cookie: {
        text: 'This website uses cookies. By continuing using this site, you agree to the usage of cookies.',
        ok: 'OK',
        no: 'no',
        moreInfo: 'More info'
    }
}