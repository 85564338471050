<script>
export default {
    name: 'BookShopWidgets',
    methods: {
      getBookShopWidgetElement( id, type, shop ) {
        // console.log('gbswe called', id , type , shop )
        if ( shop == 'bod' ) {
          return `<!-- ### Begin BoD ShopWidget --><div id="bodShopWidget_${id}_${type}" class="bodShopWidget"></div><!-- ### End BoD ShopWidget -->`
        }
      },
      addBookShopWidgetsScript( id, types, shop ) {
        // console.log('absws called', id , types , shop )
        if ( shop == 'bod' ) {
          var scriptEl = document.createElement('script')
          scriptEl.type = "text/javascript"
          scriptEl.src = "https://www.bod.de/public/js/bod/v1.1/shopWidget.min.js"
          document.getElementById('add-scripts').appendChild( scriptEl )
          var scriptEl2 = document.createElement('script')
          scriptEl2.type = 'text/javascript'
          var el2Html = `books = [];`
          for ( var type of types ) {
            el2Html += `\nbooks.push({"objID":"${id}","swKey":"0e2aaf0fb2cd40b5606f8a7914b25534","type":"${type}","size":"small","font":"nonSerif","shadow":false,"contour":true,"coverContour":false,"fontColor":"#000","contourColor":"#ccc","shadowBtn":false,"contourBtn":false,"bgColor":"#fff","btnFontColor":"#fff","btnColor":"#fd6041","btnContourColor":"#fd6041","shop":"de","mandantShopUrl":"https://www.bod.de/buchshop","lang":"de"});`
          }
          // books.push({"objID":"3586316","swKey":"0e2aaf0fb2cd40b5606f8a7914b25534","type":"print","size":"small","font":"nonSerif","shadow":false,"contour":true,"coverContour":false,"fontColor":"#000","contourColor":"#ccc","shadowBtn":false,"contourBtn":false,"bgColor":"#fff","btnFontColor":"#fff","btnColor":"#fd6041","btnContourColor":"#fd6041","shop":"de","mandantShopUrl":"https://www.bod.de/buchshop","lang":"de"})`
          scriptEl2.innerHTML = el2Html
          document.getElementById('add-scripts').appendChild( scriptEl2 )
        }
      }
    }

}
</script>