<template>
  <!-- welcome -->
    <div :key="randomKey" id="home-blog" :class="`container-fluid`">
      <div class="container flex-row content-wrap content-between align-start blog-card blog-card-big">
        <!-- <div class="col-12 flex-column align-center content-center text-center">
          <h2 >Neuster Eintrag</h2>
          <span class="divider "></span>
        </div> -->
        <div :class="`blog-card-image type-${currentEntry.type} col-12 col-md-6`">
          <img class="hover-shadow-big smooth-transition-fast" :src="getImagePath( currentEntry.image, currentEntry.imagetype )" />
          <span class="image-right" v-html="'<i>Bild:</i> '+currentEntry.imageRight"></span>
        </div>
        <div class="col-12 col-md-6 col-xl-5">
          <div :class="`blog-card-title type-${currentEntry.type}`">
            <h2>{{ currentEntry.title }}</h2>
            <span :class="`blog-card-entry-date type-${currentEntry.type}`">{{ getDateString(currentEntry.entryDate) }}</span>
          </div>
          <div :class="`blog-card-description type-${currentEntry.type}`" v-html="currentEntry.intro"></div>
            <div :class="`blog-card-morelink type-${currentEntry.type}`">
              <a class="smooth-transition-fast" :href="getLanguageLink('/blog/'+currentEntry.url)">
                {{ $tl('blog.moreLink') }}
                <img class="link-icon" :src="getImagePath('arrow-right', 'svg')" />
              </a>
            </div>
        </div>
      </div>
      <div v-if="myEntries.length > 0" class="container flex-row content-wrap content-center align-start home-container">
        <div class="col-12 home-container-centered">
          <h2>{{ $tl('blog.moreEntries') }}</h2>
          <span class="divider"></span>
        </div>
        <template  v-for="(entry, idx) in myEntries"
          :key="entry.id">
          <transition name="fade">
            <div class="col-12 col-md-4 blog-card hover-shadow-big smooth-transition-fast"
              v-show="showBlogEntries > idx">
              <div :class="`blog-card-image type-${entry.type}`">
                <img class="hover-shadow-big smooth-transition-fast" :src="getImagePath( entry.image, entry.imagetype )" />
                <span class="image-right" v-html="'<i>Bild:</i> '+entry.imageRight"></span>
              </div>
              <div :class="`blog-card-title type-${entry.type}`">
                <h3>{{ entry.title }}</h3>
                <span :class="`blog-card-entry-date type-${entry.type}`">{{ getDateString(entry.entryDate) }}</span>
              </div>
              <div :class="`blog-card-description type-${entry.type}`" v-html="entry.intro"></div>
              <div :class="`blog-card-morelink type-${entry.type}`">
                <a class="smooth-transition-fast" :href="getLanguageLink('/blog/'+entry.url)">
                  {{ $tl('blog.moreLink') }}
                  <img class="link-icon" :src="getImagePath('arrow-right', 'svg')" />
                </a>
              </div>
            </div>
          </transition>
        </template>
        <div class="col-12 home-container-centered" v-show="showBlogEntries < myEntries.length">
          <span class="divider"></span>
          <span class="button-link hover-shadow hover-scale-little smooth-transition-fast"
            @click="showBlogEntries += showBlogEntriesStep" >
            {{ $tl('blog.showMore') }}
            <img class="link-icon" :src="getImagePath('arrow-down', 'svg')" />
          </span>
        </div>
      </div>
    </div>
</template>

<script>
import GenerateImagePaths from '../mixins/GenerateImagePaths.vue'
import LanguageSwitching from '../mixins/LanguageSwitching.vue'

export default {
  name: 'BlogEntries',
  emits: [],
  props: {
    entries: Array
  },
  data() {
    return {
      showBlogEntries: 3,
      showBlogEntriesStep: 3
    }
  },
  computed: {
    randomKey: function() {
      return this.randPrefix+'-'+this.randPart
    },
    currentEntry: function() {
      var myEnts = new Array(...this.entries)
      myEnts.sort((a,b) => {
        return a.entryDate == b.entryDate ? 0 : (a.entryDate < b.entryDate ? 1 : -1 )
      })
      return myEnts[0]
    },
    myEntries: function() {
      var myEnts = new Array(...this.entries)
      // console.log('myEntries called')
      myEnts.sort((a,b) => {
        return a.entryDate == b.entryDate ? 0 : (a.entryDate < b.entryDate ? 1 : -1 )
      })
      myEnts.shift()
      // console.log(myEnts)
      return myEnts
    }
  },
  components: {
  },
  mixins: [
    GenerateImagePaths,
    LanguageSwitching
  ],
  methods: {
    getDateString( dateStr ) {
      var myDateStr = new Date(dateStr)
      //console.log(dateStr, myDateStr)
      return myDateStr.toLocaleString('de-DE', {day: 'numeric', month: 'long', year: 'numeric'} )
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>