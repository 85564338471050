<script>
export default {
  name: 'AnimateParallaxElements',
  data() {
    return {
    }
  },
  mounted() {
    this.$nextTick(function () {
      this.parallaxInit()
    })
    window.addEventListener('scroll', () => this.parallaxHandling())
    //console.log('currentRoute',this.currentRoute)
    // this.selectedLanguage = this.i18n.locale
    //console.log( 'locale stuff',this.i18n.locale, this.selectedLanguage, this.$tl('buttons.back'))
  },
  methods: {
    parallaxInit() {
      for ( var elem of document.getElementsByClassName('parallax-elem') ) {
        elem.parentNode.style.position = 'relative'
      }
    },
    parallaxHandling() {  // TODO 
      for ( var elem of document.querySelectorAll('.parallax-elem') ){
        console.log('elem dataset', elem.dataset, elem.dataset.factor)
        elem.style.transform = 'translate3D(0px,'+window.scrollY * elem.dataset.factor+'px, 0px)';
      }
    }
  }

}
</script>