<template>
  <!-- welcome -->
    <div :key="randomKey" id="welcome" :class="`container-fluid`" :style="`background-image: url('${getImagePath('mh-new-2','svg')}')`">
        <div class="welcome-shader">
          <div class="welcome container">
              <h1 class="hover-scale-little smooth-transition-fast">{{ $tl('welcome.title') }}</h1>
              <span class="subtitle">{{ $tl('welcome.subtitle') }}</span>
              <span class="divider"></span>
              <p> {{ $tl('welcome.text') }}</p>
          </div>
          <div class="parallax-elem ptop-50 pright-10" data-factor=".75">
            <div class="sticker hover-shadow-big-dark smooth-transition hover-scale">
              <p class="sticker-label">{{ $tl('sticker.newBlogEntry') }}:</p>
              <a class="sticker-link text-hover-shine smooth-transition-fast hover-scale-little" :href="getLanguageLink('/blog/magische-spiele')">Magische Spiele</a>
            </div>
          </div>
          <!-- <div class="parallax-elem ptop-50 pleft-10" pstart="50" pfactor="30">
            Parallax-Test 50-50
          </div>
          <div class="parallax-elem ptop-25 pright-10" pstart="25" pfactor="50">
            Parallax-Test 25-30
          </div> -->
        </div>
    </div>
</template>

<script>
import GenerateImagePaths from '../mixins/GenerateImagePaths.vue'
import LanguageSwitching from '../mixins/LanguageSwitching.vue'

export default {
  name: 'StartpageWelcome',
  emits: [],
  props: {
  },
  data() {
    return {
    }
  },
  computed: {
    randomKey: function() {
      return this.randPrefix+'-'+this.randPart
    }
  },
  components: {
  },
  mixins: [
    GenerateImagePaths,
    LanguageSwitching
  ],
  mounted() {
    //console.log('currentRoute',this.currentRoute)
    // this.selectedLanguage = this.i18n.locale
    //console.log( 'locale stuff',this.i18n.locale, this.selectedLanguage, this.$tl('buttons.back'))
  },
  methods: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>