<script>
export default {
    name: 'UpdateDocumentHeader',
    methods: {
      updateDocumentHeader( pageName, pageDescription = null ) {
        var headElement = document.getElementsByTagName('head')[0]
        var removeElements = []
        var addElements = []
        for ( var el of headElement.children ) {
          if ( el.tagName == 'TITLE' ){
            //console.log(el)
            removeElements.push(el)
            var newEl = document.createElement('title')
            newEl.innerHTML = `Michael Hitzelberger | ${pageName}`
            addElements.push(newEl)
          }
          // console.log(el)
          if ( el.tagName == 'META' && el.name == 'description' && pageDescription ){
            removeElements.push(el)
            newEl = document.createElement('meta')
            newEl.name = 'description'
            newEl.content = pageDescription
            addElements.push(newEl)
          }
          
        }
        for ( el of removeElements ){
          // console.log('removing', el)
          headElement.removeChild(el)
        }
        for ( el of addElements ) {
          // console.log('adding', el)
          headElement.appendChild(el)
        }
      }
    }

}
</script>