<script>
export default {
    name: 'GenerateImagePaths',
    methods: {
      getImagePath( filename, filetype, prefix='' ) {
          var images;
          switch( filetype ) {
            case 'png':
              images = require.context('../assets/png/', false, /\.png$/)
              break
            case 'jpg':
              images = require.context('../assets/jpg/', false, /\.jpg$/)
              break
            case 'svg':
              images = require.context('../assets/svg/', false, /\.svg$/)
          }
          return images(`./${prefix}${filename}.${filetype}`)
      }
    }

}
</script>